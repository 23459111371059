//index.tsx
/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme, CommonActions } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName, Pressable, Image, Text, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import ModalScreen from '../screens/ModalScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import TabOneScreen from '../screens/TabOneScreen';
import TabTwoScreen from '../screens/TabTwoScreen';
// import TabThreeScreen from '../screens/TabThreeScreen';
// import TabFourScreen from '../screens/TabFourScreen';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';


export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Login" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();



function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const [identifier, setIdentifier] = useState<string | null>(null);
  // console.log("Identifier in RootNavigator1: "+identifier)
  const removeIdentifier = async () => {
    try {
      const identifier = await AsyncStorage.getItem('identifier');
  
      if (identifier !== null) {
        const response = await axios.delete(`https://primapp.ro/node/deleteData/${identifier}`, {
          headers: {
            Authorization: `Bearer 1a2b3c4d5e6f7g8h9i0j1k2l3m4n5o6p7q8r9s0t1u2v3w4x5y6z7a8b9c0d` // Replace with your actual API key
          }
        });
  
        if (response.status === 200) {
          console.log(response.data.message);
        }
      }
  
      await AsyncStorage.removeItem('identifier');
      setIdentifier(null);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const loadIdentifier = async () => {
      const storedIdentifier = await AsyncStorage.getItem('identifier');
      setIdentifier(storedIdentifier);
    };
    loadIdentifier();
  }, []);
  // console.log("Identifier in RootNavigator2: "+identifier)
  return (
    <BottomTab.Navigator
      initialRouteName="TabTwo"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}>
      {/* <BottomTab.Screen
        name="TabOne"
        component={TabOneScreen}
        options={({ navigation }: RootTabScreenProps<'TabOne'>) => ({
          title: 'Crewing',
          tabBarIcon: ({ color }) => <TabBarIcon name="compass" color={color} />,
          headerRight: () => {
            if (identifier) {
              // If the identifier exists, show the "Deloghează-te" button and Navigate to Login
              return (
                <Pressable
                  onPress={() => {
                    removeIdentifier();
                    navigation.dispatch(
                      CommonActions.reset({
                        index: 0,
                        routes: [
                          {
                            name: 'TabOne',
                            key: 'TabOne-' + Date.now().toString(),
                            params: {},
                          },
                        ],
                      })
                    );
                  }}
                  style={({ pressed }) => ({
                    opacity: pressed ? 0.5 : 1,
                  })}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontSize: 16, marginRight: 15 }}>Deloghează-te</Text>
                    <FontAwesome
                      name="sign-out"
                      size={25}
                      color={Colors[colorScheme].text}
                      style={{ marginRight: 15 }}
                    />
                  </View>
                </Pressable>
              );
            } else {
              // If the identifier doesn't exist, return null to show nothing
              return null;
            }
          },
          headerLeft: () => (
            <Image
              source={require('../assets/images/crewing1.png')}
              style={{ width: 60, height: 60 }}
            />
          ),
        })}
      /> */}
      <BottomTab.Screen
        name="TabTwo"
        component={TabTwoScreen}
        options={({ navigation }: RootTabScreenProps<'TabTwo'>) => ({
          title: 'NAVMAR SHIPPING & SERVICE SRL',
          tabBarLabel: 'SHIPPING', // TabBarLabel is used to show the label on the bottom tab bar
          tabBarIcon: ({ color }) => <TabBarIcon name="ship" color={color} />,
          headerTitleStyle: {
            fontSize: 14, // Adjust as needed
            marginRight: 50, // Adjust as needed
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
          headerRight: () => {
            if (identifier) {
              // If the identifier exists, show the "Deloghează-te" button
              return (
                <Pressable
                  onPress={() => {
                    removeIdentifier();
                    navigation.dispatch(
                      CommonActions.reset({
                        index: 0,
                        routes: [
                          {
                            name: 'TabOne',
                            key: 'TabOne-' + Date.now().toString(),
                            params: {},
                          },
                        ],
                      })
                    );
                  }}
                  style={({ pressed }) => ({
                    opacity: pressed ? 0.5 : 1,
                  })}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontSize: 16, marginRight: 15 }}></Text>
                    <FontAwesome
                      name="sign-out"
                      size={25}
                      color={Colors[colorScheme].text}
                      style={{ marginRight: 15 }}
                    />
                  </View>
                </Pressable>
              );
            } else {
              // If the identifier doesn't exist, return null to show nothing
              return null;
            }
          },
          headerLeft: () => (
            <Image
              source={require('../assets/images/Navmarlogo.png')}
              style={{ width: 60, height: 60 }}
            />
          ),
        })}
      />
      {/* <BottomTab.Screen
        name="TabThree"
        component={TabThreeScreen}
        options={({ navigation }: RootTabScreenProps<'TabThree'>) => ({
          title: 'Programări',
          tabBarIcon: ({ color }) => <TabBarIcon name="calendar" color={color} />,
          headerRight: () => {
            if (identifier) {
              // If the identifier exists, show the "Deloghează-te" button
              return (
                <Pressable
                  onPress={() => {
                    removeIdentifier();
                    navigation.dispatch(
                      CommonActions.reset({
                        index: 0,
                        routes: [
                          {
                            name: 'TabOne',
                            key: 'TabOne-' + Date.now().toString(),
                            params: {},
                          },
                        ],
                      })
                    );
                  }}
                  style={({ pressed }) => ({
                    opacity: pressed ? 0.5 : 1,
                  })}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontSize: 16, marginRight: 15 }}>Deloghează-te</Text>
                    <FontAwesome
                      name="sign-out"
                      size={25}
                      color={Colors[colorScheme].text}
                      style={{ marginRight: 15 }}
                    />
                  </View>
                </Pressable>
              );
            } else {
              // If the identifier doesn't exist, return null to show nothing
              return null;
            }
          },
          headerLeft: () => (
            <Image
              source={require('../assets/images/navmar.png')}
              style={{ width: 60, height: 60 }}
            />
          ),
        })}
      />
      <BottomTab.Screen
        name="TabFour"
        component={TabFourScreen}
        options={({ navigation }: RootTabScreenProps<'TabFour'>) => ({
          title: 'Plăți',
          tabBarIcon: ({ color }) => <TabBarIcon name="credit-card" color={color} />,
          headerRight: () => {
            if (identifier) {
              // If the identifier exists, show the "Deloghează-te" button
              return (
                <Pressable
                  onPress={() => {
                    removeIdentifier();
                    navigation.dispatch(
                      CommonActions.reset({
                        index: 0,
                        routes: [
                          {
                            name: 'TabOne',
                            key: 'TabOne-' + Date.now().toString(),
                            params: {},
                          },
                        ],
                      })
                    );
                  }}
                  style={({ pressed }) => ({
                    opacity: pressed ? 0.5 : 1,
                  })}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontSize: 16, marginRight: 15 }}>Deloghează-te</Text>
                    <FontAwesome
                      name="sign-out"
                      size={25}
                      color={Colors[colorScheme].text}
                      style={{ marginRight: 15 }}
                    />
                  </View>
                </Pressable>
              );
            } else {
              // If the identifier doesn't exist, return null to show nothing
              return null;
            }
          },
          headerLeft: () => (
            <Image
              source={require('../assets/images/navmar.png')}
              style={{ width: 60, height: 60 }}
            />
          ),
        })}
      /> */}
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}

