//SideMenu.tsx
import React, { useEffect, useState } from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet, Dimensions, ScrollView } from 'react-native';
import { FontAwesome, FontAwesome5, Fontisto  } from '@expo/vector-icons';
import Animated from 'react-native-reanimated';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { nanoid } from 'nanoid';


export default function SideMenu({ isVisible, onClose, onSelectUrl }: { isVisible: boolean, onClose: () => void, onSelectUrl: (url: string) => void }) {
    const windowWidth = Dimensions.get('window').width;
    const translateX = isVisible ? 0 : -windowWidth;
  
    const sidebarStyle = {
      ...styles.sidebar,
      transform: [{ translateX: translateX }],
    };
  

    const [identifier, setIdentifier] = useState<string | null>(null);
    const [url, setUrl] = useState(``);
    const [Result, setResult] = useState('');
    const [Token, setToken] = useState('');

    //Setting the identifier in the browser local storage using AsycStorage.
    const getIdentifier = async () => {
        try {
        const storedIdentifier = await AsyncStorage.getItem('identifier');
        //If the stored identifier exists set state with existing identifier string
        if (storedIdentifier) {
            setIdentifier(storedIdentifier);
            // console.log('storedIdentifier: ' + storedIdentifier);
            setUrl(`https://primapp.ro/player.htm?f=RhgAggIi&mt=${storedIdentifier}&pg=`);
        } else {
            //If the identifier doesn't exist then set it in the local storage using the obtained API token above using storeData function above
            Login();
        }
        } catch (error) {
        console.error(error);
        }
    }
    const Login = async () => {
        try {
          const uniqueString = nanoid(); // generate a unique string using nanoid
          const token = uniqueString; // set token to the generated unique string
          AsyncStorage.setItem('identifier', token);
          setToken(token);
          setIdentifier(token);
          setUrl(`https://primapp.ro/player.htm?f=RhgAggIi&mt=${token}&pg=`);
        } catch (error) {
          console.error(error);
        }
      };
    
      useEffect(() => {
        getIdentifier();
      }, []);
  
    return (
      <Animated.View style={sidebarStyle}>
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-end' }}>
          {/* Buttons here */}
          <TouchableOpacity style={styles.button} onPress={() => onSelectUrl(url+"PDA")}>
            <FontAwesome5 name="file-invoice-dollar" size={24} color="#1F4181" />
            <Text style={styles.buttonText}>PDA</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={() => onSelectUrl(url+"Voyages")}>
            <Fontisto name="ship" size={24} color="#1F4181" />
            <Text style={styles.buttonText}>Voyages</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={() => onSelectUrl(url+"FDA")}>
            <FontAwesome name="wpforms" size={24} color="#1F4181" />
            <Text style={styles.buttonText}>FDA</Text>
          </TouchableOpacity>
          {/* Add more buttons as needed */}
          <TouchableOpacity style={styles.button} onPress={onClose}>
            <FontAwesome name="close" size={24} color="#1F4181" />
            <Text style={styles.buttonText}>Close</Text>
          </TouchableOpacity>
        </ScrollView>
      </Animated.View>
    );
  }
  
const styles = StyleSheet.create({
    sidebar: {
      position: 'absolute',
      left: 0,
      width: '40%',
      maxWidth: 180, // Set your maximum width in pixels
      height: '100%',
      backgroundColor: 'white',
      padding: 20,
      justifyContent: 'space-between',
    },
    text: {
      fontSize: 18,
      marginBottom: 10,
    },
    button: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 10,
    },
    buttonText: {
      marginLeft: 10,
    },
  });
