import { StyleSheet, Image, TouchableOpacity } from 'react-native';
import EditScreenInfo from '../components/Sesizari';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { parseString } from 'react-native-xml2js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { nanoid } from 'nanoid';
//implementing side menu
import SideMenu from '../components/SideMenuShipping';
import { FontAwesome } from '@expo/vector-icons';

export default function TabTwoScreen({ navigation }: RootTabScreenProps<'TabTwo'>) {
  const [url, setUrl] = useState(``);
  const [Result, setResult] = useState('');
  const [Token, setToken] = useState('');
  const [identifier, setIdentifier] = useState<string | null>(null);
  //for side menu
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);
  
  //Setting the identifier in the browser local storage using AsycStorage.
  const getIdentifier = async () => {
    try {
      const storedIdentifier = await AsyncStorage.getItem('identifier');
      //If the stored identifier exists set state with existing identifier string
      if (storedIdentifier) {
        setIdentifier(storedIdentifier);
        // console.log('storedIdentifier: ' + storedIdentifier);
        setUrl(`https://primapp.ro/player.htm?f=RhgAggIi&mt=${storedIdentifier}&pg=Shipping`);
      } else {
          //If the identifier doesn't exist then set it in the local storage using the obtained API token above using storeData function above
        Login();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const Login = async () => {
    try {
      const uniqueString = nanoid(); // generate a unique string using nanoid
      const token = uniqueString; // set token to the generated unique string
      AsyncStorage.setItem('identifier', token);
      setToken(token);
      setIdentifier(token);
      setUrl(`https://primapp.ro/player.htm?f=RhgAggIi&mt=${token}&pg=Shipping`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getIdentifier();
  }, []);

  // console.log('TabOneLoginResult: '+Result);
  // console.log('TabOneLoginToken: '+Token);
  // console.log('TabOneLoginidentifier: '+Token);
  // console.log('IdentifierSetinStorage: ' + identifier);
  // console.log('TabOneurl: '+url);
  return (
    <View style={styles.container}>
      <EditScreenInfo path="/screens/TabTwoScreen.tsx" reloadUrl={selectedUrl || url} />
      <TouchableOpacity style={styles.menuButton} onPress={() => setIsMenuVisible(true)}>
        <FontAwesome name="bars" size={24} color="black" />
      </TouchableOpacity>
      <SideMenu
        isVisible={isMenuVisible}
        onClose={() => setIsMenuVisible(false)}
        onSelectUrl={(url) => {
          setSelectedUrl(url);
          setIsMenuVisible(false);
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 0, // Optional: make the button circular
    elevation: 5, // Optional: add shadow for Android
    shadowColor: '#000', // Optional: add shadow for iOS
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
    // width: '40%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
