import * as WebBrowser from 'expo-web-browser';
import { StyleSheet, TouchableOpacity, SafeAreaView, Platform  } from 'react-native';

import Colors from '../constants/Colors';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';

import { WebView } from 'react-native-webview';
import Iframe from 'react-iframe';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { parseString } from 'react-native-xml2js';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function EditScreenInfo({ path, reloadUrl }: { path: string, reloadUrl:string }) {

  const url = reloadUrl;

  if (Platform.OS === 'web') {
    return (
      <Iframe url={url}
              width="100%"
              height="100%"
              id=""
              className=""
              display="block"
              frameBorder={0}
              position="relative"
              sandbox={["allow-scripts","allow-forms","allow-modals","allow-same-origin", "allow-popups-to-escape-sandbox", "allow-popups"]}
              />
    );
  } else {
    return (
      <View>
        <SafeAreaView style={{ flex: 1 }}>
          <WebView 
            source={{ uri: url }} 
          />
        </SafeAreaView>
      </View>
    );
  } 
}


const styles = StyleSheet.create({
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
});
