import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image, ImageSourcePropType, Alert } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import { parseString } from 'react-native-xml2js';
import Iframe from 'react-iframe';

const API_KEY = 'AIzaSyBk3NNP81Ehvd2LNZpHTCLVTYjNYJJQKBM';
const API_URL = `https://vision.googleapis.com/v1/images:annotate?key=${API_KEY}`;

export default function EditScreenInfo({ path }: { path: string }) {
  const [showIframe, setShowIframe] = useState(false);
  const [imageUri, setImageUri] = useState<string | null>(null);
  const [ocrText, setOcrText] = useState<string>('');
  const [ocrDetails, setocerDetails] = useState<string>('');
  const [Result, setResult] = useState('');
  const [Instanceid, setInstanceid] = useState('');
  const [InstanceLink, setInstanceLink] = useState('');
  const [Token, setToken] = useState('');
  const [SeriaNr, setSeriaNr] = useState('');
  const [NumePrenume, setNumePrenume] = useState('');
  const [CNP, setCNP] = useState('');
  const [Address, setAddress] = useState('');

  //perfectforms API connect to SOAP
  //Login to get the token first
  const Login = async () => {
    try {
      const soapRequest = `
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:api="http://www.PerfectForms.com/API">
      <soapenv:Header/>
      <soapenv:Body>
        <api:Login>
            <!--Optional:-->
            <api:emailAddress>smartadmin@perfectforms.ro</api:emailAddress>
            <!--Optional:-->
            <api:password>@Qplates99</api:password>
            <!--Optional:-->
            <api:securityKey>6a7ae8c9fe54438ab</api:securityKey>
        </api:Login>
      </soapenv:Body>
    </soapenv:Envelope>
    `;
  
      const response = await axios({
        method: 'post',
        url: 'https://primapp.ro/api/api.asmx',
        headers: {
          'Content-Type': 'text/xml;charset=UTF-8',
          'SOAPAction': 'http://www.PerfectForms.com/API/Login'
        },
        data: soapRequest
      });
  
      const xml = response.data;
  
      parseString(xml, (err, result) => {
        console.log('Original:', result);
        const token = result['soap:Envelope']['soap:Body'][0]['LoginResponse'][0].token[0];
        console.log('Login.Token:', token);
        setToken(token);
        const LoginResult = result['soap:Envelope']['soap:Body'][0]['LoginResponse'][0].LoginResult[0];
        console.log('Login.LoginResult:', LoginResult);
        setResult(LoginResult);
      });
    } catch (error) {
      console.error(error);
    }
  };
  //select the image
  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status === 'granted') {
      const result = await ImagePicker.launchImageLibraryAsync();
      if (result.canceled === false && 'uri' in result) {
        setImageUri(result.uri as string);
      }      
    } else {
      Alert.alert('Media library permissions required', 'Please enable media library permissions in order to use this feature.', [{ text: 'OK' }]);
    }
  };
  //ExtractText From Image
  const handleOCR = async () => {
    if (imageUri) {
      try {
        const base64Image = await convertImageToBase64(imageUri);
        const ocrText = await getOCRText(base64Image);
        setOcrText(ocrText);

        //Formatting the response:
        const seriaRegex = /SERIA\s+(\S+)/i;
        const nrRegex = /NR (\d+)/i;
        const nameRegex = /Nume\/Nom\/Last name\n([A-ZĂÎȘȚ ]+)/i;
        const prenameRegex =/Prenume\/Prenom\/First name\n([A-ZĂÎȘȚ ]+(?:-[A-ZĂÎȘȚ ]+)*)/i;
        const cnpRegex = /CNP (\d{13})/i;
        const addressRegex = /Domiciliu\/Adresse\/Address[\s\S]*?((?:Str\.|Ale\.)[^\n]*)(?=(Emisă|$))/m



        const seriaMatch = ocrText.match(seriaRegex);
        const nrMatch = ocrText.match(nrRegex);
        const nameMatch = ocrText.match(nameRegex);
        const prenameMatch = ocrText.match(prenameRegex);
        const cnpMatch = ocrText.match(cnpRegex);
        const addressMatch = ocrText.match(addressRegex);

        let seria = seriaMatch ? seriaMatch[1] : '';
        let nr = nrMatch ? nrMatch[1] : '';
        let name = nameMatch ? nameMatch[1] : '';
        let prename = prenameMatch ? prenameMatch[1] : '';
        let cnp = cnpMatch ? cnpMatch[1] : '';
        let address = addressMatch ? addressMatch[1] : '';

        console.log('Seria si Nr:', seria+' '+nr);
        setSeriaNr(seria+' '+nr);
        console.log('Nume si Prenume:', name+' '+prename);
        setNumePrenume(name+' '+prename);
        console.log('CNP:', cnp);
        setCNP(cnp);
        console.log('Address:', address);
        setAddress(address);


        let OCRAllDetails = 'Seria si Nr:'+seria+' '+nr+'/Nume si Prenume:'+name+' '+prename+'/CNP:'+cnp+'/Address:'+address;

        setocerDetails(OCRAllDetails);


      } catch (error) {
        console.log(error);
      }
    }
  };
  //Convert the image
  const convertImageToBase64 = async (imageUri: string): Promise<string> => {
    const response = await fetch(imageUri);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  };
  //GetTheReading from Google Vision
  const getOCRText = async (base64Image: string): Promise<string> => {
    const requestData = {
      requests: [
        {
          image: { content: base64Image.split(',')[1] },
          features: [{ type: 'TEXT_DETECTION' }],
        },
      ],
    };
    const response = await axios.post(API_URL, requestData);
    const annotations = response.data.responses[0]?.textAnnotations;
    if (annotations) {
      let ocrText = '';
      annotations.forEach((annotation: { description: string; }) => {
        ocrText += annotation.description + ' ';
      });
      return ocrText.trim();
    } else {
      return 'No text detected.';
    }
  };
  
  //perfectforms API connect to SOAP
  //Create a new instance in form Cont Nou - this calls GetInstanceLink when done
  const ContNou = async () => {
    try {
      const soapRequest = `
      <soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:tem="http://tempuri.org/">
        <soap:Header/>
        <soap:Body>
            <tem:Add>
              <!--Optional:-->
              <tem:tokenIn>${Token}</tem:tokenIn>
              <!--Optional:-->
              <tem:instance>
                  <tem:Page_1_PersoanaFizica_Nume_prenume>${NumePrenume}</tem:Page_1_PersoanaFizica_Nume_prenume>
                  <tem:Page_1_PersoanaFizica_CNP>${CNP}</tem:Page_1_PersoanaFizica_CNP>
                  <tem:Page_1_PersoanaFizica_CI>${SeriaNr}</tem:Page_1_PersoanaFizica_CI>
                  <tem:Page_1_PersoanaFizica_Adresa>${Address}</tem:Page_1_PersoanaFizica_Adresa>
              </tem:instance>
            </tem:Add>
        </soap:Body>
      </soap:Envelope>
    `;
  
      const response = await axios({
        method: 'post',
        url: 'https://primapp.ro:443/api/apps/4NgAg2AgAgAgGAQJ_Cont_Nou.asmx',
        headers: {
          'Content-Type': 'text/xml;charset=UTF-8',
          'SOAPAction': 'http://tempuri.org/Cont_NouServiceSoap/AddRequest'
        },
        data: soapRequest
      });
  
      const xml = response.data;
  
      parseString(xml, (err, result) => {
        console.log('Original:', result);
        const token = result['soap:Envelope']['soap:Body'][0]['AddResponse'][0].tokenOut[0];
        console.log('AddResponse.Token:', token);
        setToken(token);
        const AddResult = result['soap:Envelope']['soap:Body'][0]['AddResponse'][0].AddResult[0];
        console.log('AddResponse.Result:', AddResult);
        setResult(AddResult);
        const instanceId = result['soap:Envelope']['soap:Body'][0]['AddResponse'][0].instanceId[0];
        console.log('AddResponse.instanceId:', instanceId);
        setInstanceid(instanceId);
        
      });

    } catch (error) {
      console.error(error);
    }
  };

  //This is just in case we want to send data using URL parameters but it seems the chars transmitted are not OK
  // const OpenContNou = () => {
  //   setShowIframe(true);
  // };

  //perfectforms API connect to SOAP
  //GetFormInstanceMetaDataResponse Get the instance link from created instance above to be used further when opening
  const GetInstanceLink = async () => {
    try {
      const soapRequest = `
      <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:api="http://www.PerfectForms.com/API">
      <soapenv:Header/>
      <soapenv:Body>
         <api:GetFormInstanceMetaData>
            <!--Optional:-->
            <api:tokenIn>${Token}</api:tokenIn>
            <!--Optional:-->
            <api:formId>atAgg5gAgAAgGAwJ</api:formId>
            <api:instanceId>${Instanceid}</api:instanceId>
         </api:GetFormInstanceMetaData>
      </soapenv:Body>
   </soapenv:Envelope>
    `;
  
      const response = await axios({
        method: 'post',
        url: 'https://primapp.ro/api/api.asmx',
        headers: {
          'Content-Type': 'text/xml;charset=UTF-8',
          'SOAPAction': 'http://www.PerfectForms.com/API/GetFormInstanceMetaData'
        },
        data: soapRequest
      });
  
      const xml = response.data;
  
      parseString(xml, (err, result) => {
        console.log('Original:', result);
        const token = result['soap:Envelope']['soap:Body'][0]['GetFormInstanceMetaDataResponse'][0].tokenOut[0];
        console.log('GetFormInstanceMetaDataResponse.Token:', token);
        setToken(token);
        const AddResult = result['soap:Envelope']['soap:Body'][0]['GetFormInstanceMetaDataResponse'][0].GetFormInstanceMetaDataResult[0];
        console.log('GetFormInstanceMetaDataResponse.Result:', AddResult);
        setResult(AddResult);
        const InstanceLink = result['soap:Envelope']['soap:Body'][0]['GetFormInstanceMetaDataResponse'][0].link[0];
        console.log('GetFormInstanceMetaDataResponse.InstanceLink:', InstanceLink);
        setInstanceLink(InstanceLink);
      });
      setShowIframe(true);
      console.log('ShowIframe?:',showIframe);
    } catch (error) {
      console.error(error);
    }
  };
  

  useEffect(() => {
    Login();
  }, []);

  //This is just in case we want to send data using URL parameters but it seems the chars transmitted are not OK
  // const url = `https://primapp.ro/player.htm?f=oAgAgAgB&np=${NumePrenume}&cnp=${CNP}&sn=${SeriaNr}&addr=${Address}`;
  
  const url = InstanceLink;

  return (
    <View style={styles.container}>
      {showIframe ? (
          <Iframe
            url={url}
            width="100%"
            height="100%"
            id=""
            className=""
            display="block"
            frameBorder={0}
            position="relative"
            sandbox={[
              'allow-scripts',
              'allow-forms',
              'allow-modals',
              'allow-same-origin',
              'allow-popups',
              'allow-popups-to-escape-sandbox',
            ]}
            styles={{
              overflowX: 'hidden',
            }}
          />
      ) : (
        <View style={styles.container}>
          <TouchableOpacity style={styles.button} onPress={pickImage}>
            <Text style={styles.buttonText}>Pick an image</Text>
            {imageUri && (
              <View>
                <Image source={{ uri: imageUri }} style={styles.image} />
                <TouchableOpacity style={styles.button} onPress={handleOCR}>
                  <Text style={styles.buttonText}>handleOCR</Text>
                </TouchableOpacity>
              </View>
            )}
            {ocrText !== '' && <Text style={styles.ocrText}>{ocrDetails}</Text>}
          {/* {ocrText !== '' && <Text style={styles.ocrText}>{ocrText}</Text>} */}
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={ContNou}>
            <Text style={styles.buttonText}>Add Cont Nou</Text>
          </TouchableOpacity>
          <Text>{Token}, {Result}, {Instanceid}</Text>
          <TouchableOpacity style={styles.button} onPress={GetInstanceLink}>
            <Text style={styles.buttonText}>GetInstanceLink</Text>
          </TouchableOpacity>
        </View>  
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#2196F3',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  buttonText: {
    color: '#fff',
    fontSize: 20,
  },
  image: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
    marginTop: 20,
  },
  ocrText: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
  },
});
